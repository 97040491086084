<template>
    <div class="py-5 px-5">
        <h3 class="ml-6 my-4">{{ title }} </h3>
        <st-data-table
            :items="metadataMappingsList"
            :fields="fields"
            :modelPresenter="presenter"
            :loading="isLoading"
            :per-page="perPage"
            responsive="md"
            hover
            :actions="actions"
            @delete="deleteMetadata"
            @edit="editMetadata"
            @view="viewMetadata"
        >
        </st-data-table>

        <gis-intersections-stage-settings-view-modal
            ref="st-gis-intersection-settings-view-modal"
        />
    </div>

</template>

<script>

import { mapGetters, mapActions } from 'vuex';
import { GisIntersectionsStageSettingsModel } from "@/modules/process-editor/models/gis-intersections/gis-intersections-stage-settings-model";
import GisIntersectionsStageSettingsViewModal from './GisIntersectionsStageSettingsViewModal.vue';

const { fields } = GisIntersectionsStageSettingsModel;

export default {
    name: 'GisIntersectionsStageSettingsListTable',
    components: {
        GisIntersectionsStageSettingsViewModal,
    },
    data() {
        return {
            presenter: GisIntersectionsStageSettingsModel.presenter,
            selectedAppType: '',
            actions: [
                {
                    name: 'delete',
                    tooltipText: this.$t('GENERAL.ACTIONS.DELETE'),
                    icon: 'trash-alt',
                    customIcon: false,
                    type: 'danger'
                },
                // {
                //     name: 'edit',
                //     icon: 'edit',
                //     tooltipText: this.$t('GENERAL.ACTIONS.EDIT'),
                //     customIcon: false,
                //     type: 'primary'
                // },
                {
                    name: 'view',
                    icon: 'eye',
                    tooltipText: this.$t('GENERAL.ACTIONS.VIEW'),
                    customIcon: false,
                    type: 'primary'
                },
            ],
        };
    },
    computed: {
        ...mapGetters({
            rows: 'processEditor/gisIntersectionSettings/rows',
            loading: 'shared/loading',
        }),
        isLoading () {
            return this.loading['processEditor/gisIntersectionSettings'] ;
        },
        fields() {
            return [
                fields.tenant_name,
                fields.layer_name,
                fields.sublayer_name,
                fields.metadata_name,
            ]
        },
        title() {
            return `${this.$t('PROCESS_EDITOR.STEPS.GIS_INTERSECTIONS.MAPPINGS_LIST_DESC')}`;
        },
        metadataMappingsList() {
            return this.rows?.length ? this.rows : [];
        },
        perPage() {
            return this.rows?.length ?? 0;
        }
    },
    methods: {
        ...mapActions({
            deleteMapping: 'processEditor/gisIntersectionSettings/doDeleteMappings',
            setActiveRecord: 'processEditor/gisIntersectionSettings/doSetActiveRecord',
        }),
        async editMetadata(row) {
            this.setActiveRecord({...row.item, order: row.index});
        },
        async viewMetadata(row) {
            this.setActiveRecord({...row.item, order: row.index});
            this.$refs['st-gis-intersection-settings-view-modal'].show();
        },
        async deleteMetadata(metadata) {
            await this.deleteMapping(metadata.index);
        }
    }
};
</script>
