<template>
    <div>
        <Loader v-if="loading['processStage/gisIntersectionSettings']" />

        <form ref="gis_workflow_step_form" class="form" novalidate="novalidate" id="st_gis_intersections_stage_settings_form">
            <div class="row mb-2">
                <div class="col-6 col-md-6 mb-2">
                    <div class="form-group">
                        <label>
                            {{ fields.tenant_id.label }} *
                        </label>
                        <st-autocomplete
                            v-model="model[fields.tenant_id.name]"
                            :options="fields.tenant_id.options"
                            :ref="fields.tenant_id.name"
                            :name="fields.tenant_id.name"
                            :formValidation="fv"
                            :model="model"
                            :field="fields.tenant_id"
                            :fields="fields"
                            @change="tenantChanged"
                        />
                    </div>
                </div>

                <div class="col-6 col-md-6 mb-2">
                    <div class="form-group">
                        <label>
                            {{ fields.layer_id.label }} *
                        </label>
                        <st-autocomplete
                            v-model="model[fields.layer_id.name]"
                            :options="fields.layer_id.options"
                            :ref="fields.layer_id.name"
                            :name="fields.layer_id.name"
                            :disabled="!model[fields.tenant_id.name]"
                            :formValidation="fv"
                            :model="model"
                            :field="fields.layer_id"
                            :fields="fields"
                            @change="layerChanged"
                        />
                    </div>
                </div>
            </div>
            <div class="row mb-2">
                <div class="col-6 col-md-6 mb-2">
                    <div class="form-group">
                        <label>
                            {{ fields.sublayer_id.label }} *
                        </label>
                        <st-autocomplete
                            v-model="model[fields.sublayer_id.name]"
                            :options="fields.sublayer_id.options"
                            :ref="fields.sublayer_id.name"
                            :name="fields.sublayer_id.name"
                            :disabled="!model[fields.layer_id.name]"
                            :model="model"
                            :field="fields.sublayer_id"
                            :fields="fields"
                            :formValidation="fv"
                            @change="sublayerChanged"
                        />
                    </div>
                </div>

                <div class="col-6 col-md-6 mb-2">
                    <div class="form-group">
                        <label>
                            {{ fields.gis_attribute_id.label }}
                        </label>
                        <st-autocomplete
                            v-model="model[fields.gis_attribute_id.name]"
                            :options="fields.gis_attribute_id.options"
                            :ref="fields.gis_attribute_id.name"
                            :name="fields.gis_attribute_id.name"
                            :disabled="!model[fields.sublayer_id.name]"
                            :model="model"
                            :field="fields.gis_attribute_id"
                            :fields="fields"
                            :formValidation="fv"
                            @change="attributeChanged"
                        />
                    </div>
                </div>
            </div>

            <!-- configure metadata -->
            <div class="row mb-2 mt-8" :key="componentKey" v-if="gisSublayerSelected">
                <div class="col-12 mb-2 mt-2">
                    <b-table-simple>
                        <b-thead class="st-data-table-head">
                            <b-tr class="st-data-table-head-row">
                                <b-th class="text-uppercase st-header-text">{{fields.metadata_name.label}}</b-th>
                                <b-th class="text-uppercase st-header-text">{{fields.intersection_type.label}}</b-th>
                                <b-th class="text-uppercase st-header-text">{{ gisValueLabel }}</b-th>
                                <b-th class="text-uppercase st-header-text">{{fields.gis_attribute_value_override.label}}</b-th>
                            </b-tr>
                        </b-thead>
                        <b-tr v-for="metaType in metaTypeList"
                            :key="metaType.key"
                            class="st-data-table-body-row"
                        >
                            <b-td>
                                <input
                                    class="form-control h-auto"
                                    type="text"
                                    placeholder="metadata"
                                    v-model="metaType.metadata_name"
                                    debounce=500
                                    @blur="blurDownSection()"
                                    :class="[
                                        { 'is-invalid': !metaType.metadata_name && !!metaType.manual_override },
                                        { 'is-valid': !!metaType.metadata_name }
                                    ]"
                                >
                            </b-td>
                            <b-td>{{metaType.intersection_type_label}}</b-td>
                            <b-td>{{metaType.completition_method_gis_value_label}}</b-td>
                            <b-td>
                                <input
                                    class="form-control h-auto"
                                    type="text"
                                    placeholder="Valoare manuala"
                                    v-model="metaType.manual_override"
                                    debounce=500
                                    @blur="blurDownSection()"
                                    :class="[
                                        { 'is-invalid': metaType.ni_manual_meta && !!metaType.metadata_name && !metaType.manual_override },
                                        { 'is-valid': !!metaType.manual_override }
                                    ]"
                                >
                            </b-td>
                        </b-tr>
                    </b-table-simple>
                </div>
            </div>

            <div class="d-flex justify-content-end w-100">
                <st-button
                    variant="primary"
                    class="font-size-h6 px-5 py-3 my-3 mr-3"
                    :callback="addMetdataMapping"
                >
                    <span>{{ $t('PROCESS_EDITOR.STEPS.GIS_INTERSECTIONS.ADD_MAPPINGS') }}</span>
                </st-button>
            </div>
        </form>
    </div>

</template>
<script>

import { mapActions, mapGetters } from "vuex";
import { createFormValidation } from "@/shared/utils/create-form-validation";
import { FormSchema } from "@/shared/form/form-schema";
import {
    GisIntersectionsStageSettingsModel,
    INTERSECTION_TYPE_NONE,
    INTERSECTION_TYPE_OPTIONS,
} from "@/modules/process-editor/models/gis-intersections/gis-intersections-stage-settings-model";

const { fields } = GisIntersectionsStageSettingsModel;

const formSchema = new FormSchema([
    fields.id,
    fields.tenant_id,
    fields.tenant_name,
    fields.layer_id,
    fields.layer_name,
    fields.sublayer_id,
    fields.sublayer_name,
    fields.gis_attribute_id,
    fields.gis_attribute_name,
    // fields.metadata_name,
    // fields.intersection_type,
    // fields.metadata_completion_method,
    // fields.gis_attribute_value_override,
]);

export default {
    name: "GisIntersectionsStageSettingsForm",
    props: {
        selectedStep: {
            type: Object,
            required: true,
            default: () => ({}),
        },
        editMode: {
            type: Boolean,
            default: false,
        },
        processId: String,
    },
    data() {
        return {
            fields,
            model: {},
            rules: formSchema.rules(),
            fv: null,
            metadataTable: [],
            forceHide: false,
            componentKey: 0,
        };
    },
    computed: {
        ...mapGetters({
            loading: "shared/loading",
            record: 'processEditor/gisIntersectionSettings/record',
        }),
        gisValueLabel() {
            return this.$t('PROCESS_EDITOR.STEPS.GIS_INTERSECTIONS.GIS_VALUE_LABEL');
        },
        gisValueLabelExtended() {
            return this.$t('PROCESS_EDITOR.STEPS.GIS_INTERSECTIONS.GIS_VALUE_LABEL_EXTENDED');
        },
        gisSublayerSelected() {
            return !!this.model[fields.sublayer_id.name];
        },
    },
    methods: {
        ...mapActions({
            addMappings: 'processEditor/gisIntersectionSettings/doAddMappings',
            setActiveRecord: 'processEditor/gisIntersectionSettings/doSetActiveRecord',
        }),
        async tenantChanged(tenantId) {
            const selectedTenant = fields.tenant_id?.options?.find(v => v.value === tenantId);
            this.model[fields.tenant_name.name] = selectedTenant?.text;

            await this.resetFormFields([
                fields.layer_id.name,
                fields.sublayer_id.name,
                fields.gis_attribute_id.name,
            ]);

            fields.layer_id.fetchParams = { tenant_id: this.model.tenant_id };
            await fields.layer_id.updateOptions();
        },
        async layerChanged(layerId) {
            const currentLayer = fields.layer_id.options?.find(v => v.value === layerId);
            const sublayers = currentLayer?.sublayers;

            this.model[fields.layer_name.name] = currentLayer?.text;
            fields.sublayer_id.options = sublayers?.map( v => {
                return {
                    text: v.name,
                    value: v.gis_id + '',
                    attributes: v.attributes,
                }
            });

            await this.resetFormFields([
                fields.sublayer_id.name,
                fields.gis_attribute_id.name,
            ]);
        },
        async sublayerChanged(sublayerId) {
            const currentSublayer = fields.sublayer_id.options?.find(v => v.value === sublayerId);
            const attributes = currentSublayer?.attributes;
            this.model[fields.sublayer_name.name] = currentSublayer?.text;

            fields.gis_attribute_id.options = attributes?.map( v => {
                return {
                    text: v.name,
                    value: v.id + '',
                }
            });

            this.model[fields.gis_attribute_name.name] = '';
            await this.resetFormFields([
                fields.gis_attribute_id.name,
            ]);
        },
        async attributeChanged(attrId) {
            const selectedAttribute = fields.gis_attribute_id?.options?.find(v => v.value === attrId);
            this.model[fields.gis_attribute_name.name] = selectedAttribute?.text;
        },
        addMetdataMapping() {
            this.fv.validate()
            .then((status) => {
                if(status=== 'Valid') {
                    const manualMeta = this.metaTypeList.filter( v => !!v.ni_manual_meta)?.[0];

                    if(
                        manualMeta?.ni_manual_meta &&
                        (
                            (!!manualMeta.metadata_name && !manualMeta.manual_override) ||
                            (!manualMeta.metadata_name && !!manualMeta.manual_override)
                        )

                    ) {
                        return;
                    }

                    const validMetas = this.metaTypeList.filter( v => {
                        return v.metadata_name && (v.ni_manual_meta ? !!v.manual_override : true)
                    });

                    if(!validMetas.length) {
                        return;
                    }

                    const mappings = [];

                    validMetas.forEach(v => {
                        mappings.push({
                            ...this.model,
                            metadata_name: v.metadata_name.replace(/\s/g, '_').normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
                            intersection_type: v.intersection_type,
                            gis_attribute_value_override: v.manual_override,
                        });
                    });

                    this.addMappings(mappings)
                    .then(() => {
                        this.initAll();
                    });
                }
            });
        },
        resetFormFields(fields) {
            for(const field of fields) {
                this.model[field] = null;
            }
        },
        async blurDownSection() {
            this.componentKey += 1;
        },
        initModel() {
            return INTERSECTION_TYPE_OPTIONS.map((v) => {
                return {
                    metadata_name: '',
                    intersection_type: v.value,
                    intersection_type_label: v.text,
                    completition_method_gis_value: v.value,
                    completition_method_gis_value_label: v.value !== INTERSECTION_TYPE_NONE ? this.gisValueLabel : '-',
                    manual_override: '',
                    ni_manual_meta: v.value === INTERSECTION_TYPE_NONE,
                    key: v.value + '_' + Math.random(),
                }
            });
        },
        initAll() {
            this.model = formSchema.initialValues({});
            this.metaTypeList = this.initModel();
        },
    },
    mounted() {
        this.fv = createFormValidation("st_gis_intersections_stage_settings_form", this.rules);
        this.fv.resetForm(true);
        this.$refs.gis_workflow_step_form.reset();
    },
    async created() {
        this.setActiveRecord(null);

        this.initAll();
    },
};
</script>
