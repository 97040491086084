import RelationToOneField from '@/shared/fields/relation-to-one-field';
import { storeAsync } from '@/app-module';

export class SublayerAttributeField {
    static relationToOne(name, label, rules, filterParams, children, options) {
        const store = storeAsync();
        return new RelationToOneField(
            name,
            label,
            (params) => {
                return new Promise((resolve) => {
                    resolve([])
                });
            },
            (record) => {
                if (!record) {
                    return null;
                }
                return {
                    value: record.id + '',
                    text: record.name,
                };
            },
            rules,
            filterParams,
            children,
            options,
        );
    }
}
