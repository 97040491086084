import GenericModel from '@/shared/models/generic-model';
import i18n from '@/shared/plugins/vue-i18n';
import StringField from '@/shared/fields/string-field';
import IdField from '@/shared/fields/id-field';
import EnumField from '@/shared/fields/enum-field';
import { OrganizationField } from '@/modules/administrator/fields/organisations-field';
import { SimpleLayerByOrganisationField } from '@/modules/process-editor/fields/simple-layer-by-organisation-field';
import { SublayerField } from '@/modules/process-editor/fields/sublayer-field';
import { SublayerAttributeField } from '@/modules/process-editor/fields/sublayer-attribute-field';

const label = (name) => i18n.t(`PROCESS_EDITOR.STEPS.GIS_INTERSECTIONS.FIELDS.${name.toUpperCase()}`);
const placeholder = (name) => i18n.t(`PROCESS_EDITOR.STEPS.GIS_INTERSECTIONS.PLACEHOLDERS.${name.toUpperCase()}`);
const enumLabel = (name, value) => i18n.t(`PROCESS_EDITOR.STEPS.GIS_INTERSECTIONS.ENUMS.${name.toUpperCase()}.${value.toUpperCase()}`)


export const INTERSECTION_TYPE_TOTAL = 'total';
export const INTERSECTION_TYPE_PARTIAL = 'partial';
export const INTERSECTION_TYPE_NONE = 'none';

export const METADATA_COMPLETION_METHOD_ACTUAL = 'use_gis_value';
export const METADATA_COMPLETION_METHOD_MANUAL = 'manual';

export const METADATA_COMPLETION_METHOD_OPTIONS = [
    {
        value: METADATA_COMPLETION_METHOD_ACTUAL,
        name: METADATA_COMPLETION_METHOD_ACTUAL,
        text: enumLabel('metadata_completion_method', METADATA_COMPLETION_METHOD_ACTUAL),
        type: 'primary'
    },
    {
        value: METADATA_COMPLETION_METHOD_MANUAL,
        name: METADATA_COMPLETION_METHOD_MANUAL,
        text: enumLabel('metadata_completion_method', METADATA_COMPLETION_METHOD_MANUAL),
        type: 'success',
    },
];

export const INTERSECTION_TYPE_OPTIONS = [
    {
        value: INTERSECTION_TYPE_TOTAL,
        name: INTERSECTION_TYPE_TOTAL,
        text: enumLabel('intersection_type', INTERSECTION_TYPE_TOTAL),
        type: 'primary'
    },
    {
        value: INTERSECTION_TYPE_PARTIAL,
        name: INTERSECTION_TYPE_PARTIAL,
        text: enumLabel('intersection_type', INTERSECTION_TYPE_PARTIAL),
        type: 'success'
    },
    {
        value: INTERSECTION_TYPE_NONE,
        name: INTERSECTION_TYPE_NONE,
        text: enumLabel('intersection_type', INTERSECTION_TYPE_NONE),
        type: 'danger'
    },
];

const fields = {
    id: new IdField('id', 'Id'),
    tenant_id: OrganizationField.relationToOne(
        'tenant_id',
        label('tenant_id'),
        { autocompleteRequired: true },
        { placeholder: placeholder('tenant_id')},
    ),
    tenant_name: new StringField( 'tenant_name', label('tenant_name')),
    layer_id: SimpleLayerByOrganisationField.relationToOne(
        'layer_id',
        label('layer_id'),
        { autocompleteRequired: true },
        { placeholder: placeholder('layer_id')},
        [{
            key: 'tenant_id',
            name: 'tenant_id',
        }],
        { asyncData: true }
    ),
    layer_name: new StringField( 'layer_name', label('layer_name')),
    sublayer_id: SublayerField.relationToOne(
        'sublayer_id',
        label('sublayer_id'),
        { autocompleteRequired: true },
        { placeholder: placeholder('sublayer_id')},
    ),
    sublayer_name: new StringField( 'sublayer_name', label('sublayer_name')),
    gis_attribute_id: SublayerAttributeField.relationToOne(
        'gis_attribute_id',
        label('gis_attribute_id'),
        { autocompleteRequired: false },
        { placeholder: placeholder('gis_attribute_id')},
    ),
    gis_attribute_name: new StringField(
        'gis_attribute_name',
        label('gis_attribute_name'),
        { required: false },
        { placeholder: placeholder('gis_attribute_name')},
    ),
    metadata_name: new StringField(
        'metadata_name',
        label('metadata_name'),
        { required: true },
        { placeholder: placeholder('metadata_name')},
    ),
    intersection_type: new EnumField(
        'intersection_type',
        label('intersection_type'),
        INTERSECTION_TYPE_OPTIONS,
        { required: false, autocompleteRequired: true },
        { placeholder: placeholder('intersection_type') },
    ),
    metadata_completion_method: new EnumField('metadata_completion_method',
        label('metadata_completion_method'),
        METADATA_COMPLETION_METHOD_OPTIONS,
        { required: false, autocompleteRequired: true },
        { placeholder: placeholder('metadata_completion_method') },
    ),
    gis_attribute_value_override: new StringField(
        'gis_attribute_value_override',
        label('gis_attribute_value_override'),
        { },
        { placeholder: placeholder('gis_attribute_value_override')},
    ),
};

export class GisIntersectionsStageSettingsModel extends GenericModel {
    static get fields() {
        return fields;
    }
}
