<template>
    <div>
        <st-modal
            id="st-modal-gis-intersections-settings-view"
            hide-header-delimiter
            hide-footer-delimiter
            size="l"
            hideable
            ref="settings-view-modal"
        >
            <template #header>
                <p class="font-size-h5 font-weight-bold mb-0 pb-0">{{ headerText }}</p>
            </template>
            <template #body>
                <gis-intersections-stage-settings-view-form
                    ref="gis-intersections-settings0-view-form"
                    @cancelForm="hide"
                >
                </gis-intersections-stage-settings-view-form>
            </template>
            <template #footer>
                <div class="d-flex justify-content-between w-100">
                    <st-button
                        variant="link"
                        class="font-size-h6 px-5 py-3 my-3 mr-3"
                        :callback="hide"
                    > {{ $t('GENERAL.CLOSE') }}
                    </st-button>
                </div>
            </template>
        </st-modal>
    </div>
</template>

<script>

import { mapGetters } from "vuex";
import GisIntersectionsStageSettingsViewForm from './GisIntersectionsStageSettingsViewForm.vue';

export default {
    name: 'GisIntersectionsStageSettingsViewModal',
    components: {
        GisIntersectionsStageSettingsViewForm,
    },
    computed: {
        ...mapGetters({
            loading: "shared/loading",
            mappings: 'processEditor/gisIntersectionSettings/selectedRecord',
        }),
        headerText() {
            return this.$t('PROCESS_EDITOR.STEPS.EDIT_GIS_INTERSECTION_SETTINGS_VIEW_MODAL_HEADER');
        },
    },
    methods: {
        show() {
            this.$refs['settings-view-modal'].show();
        },
        hide() {
            this.$refs['settings-view-modal'].hide();
        },
    },
    async created() {},
};
</script>
