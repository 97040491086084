import RelationToOneField from '@/shared/fields/relation-to-one-field';
import ApiService from '@/shared/services/api.service';

export class SimpleLayerByOrganisationField {
    static relationToOne(name, label, rules, filterParams, children, options) {
        return new RelationToOneField(
            name,
            label,
            (params) => {
                return new Promise((resolve) => {
                    if (params?.tenant_id) {
                        ApiService.query('layers-simple/me', {
                            params: {
                                query: `owner_org_id eq ${params.tenant_id}`,
                                limit: 500,
                                fields: 'id,name,sublayers',
                            }
                        })
                        .then(({ data }) => {
                            resolve(data.data?.filter(v => v.sublayers?.length > 0));
                        });
                    } else resolve([]);
                })
            },
            (record) => {
                if (!record) {
                    return null;
                }
                return {
                    value: record.id,
                    text: record.name,
                    sublayers: record.sublayers ?? [],
                };
            },
            rules,
            filterParams,
            children,
            options,
        );
    }
}
