<template>
    <div>
        <st-modal
            id="st-modal-gis-intersections-settings"
            hide-header-delimiter
            hide-footer-delimiter
            size="xl"
            hideable
            ref="modal"
        >
            <template #header>
                <p class="font-size-h5 font-weight-bold mb-0 pb-0">{{ headerText }}</p>
            </template>
            <template #body>
                <gis-intersections-stage-settings-form
                    ref="gis-intersections-settings-form"
                    :processId="processId"
                    :selectedStep="selectedStep"
                    :editMode="editMode"
                    @cancelForm="hide"
                >
                </gis-intersections-stage-settings-form>

                <gis-intersections-stage-settings-list-table
                    ref="gis-intersections-settings-list-table"
                    :processId="processId"
                    :selectedStep="selectedStep"
                    :editMode="editMode"
                    @cancelForm="hide"
                >
                </gis-intersections-stage-settings-list-table>
            </template>
            <template #footer>
                <div class="d-flex justify-content-between w-100">
                    <st-button
                        variant="link"
                        class="font-size-h6 px-5 py-3 my-3 mr-3"
                        :callback="hide"
                    > {{ $t('GENERAL.BUTTON.CANCEL') }}
                    </st-button>
                    <st-button
                        variant="primary"
                        class="font-size-h6 px-5 py-3 my-3 mr-3"
                        :callback="saveIntersectionSettings"
                    >
                        <span>{{buttonText}}</span>
                    </st-button>
                </div>
            </template>
        </st-modal>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import GisIntersectionsStageSettingsForm from './GisIntersectionsStageSettingsForm.vue';
import GisIntersectionsStageSettingsListTable from './GisIntersectionsStageSettingsListTable.vue';

export default {
    name: 'GisIntersectionsStageSettingsModal',
    components: {
        GisIntersectionsStageSettingsForm,
        GisIntersectionsStageSettingsListTable,
    },
    props: {
        selectedStep: {
            type: Object,
            required: true
        },
        editMode: {
            type: Boolean,
            default: false
        },
        processId: String,
    },
    computed: {
        ...mapGetters({
            loading: "shared/loading",
            mappings: 'processEditor/gisIntersectionSettings/rows',
        }),
        buttonText() {
            return this.editMode ? this.$t('GENERAL.BUTTON.SAVE') : this.$t('GENERAL.BUTTON.ADD');
        },
        headerText() {
            return this.editMode ?
                this.$t('PROCESS_EDITOR.STEPS.EDIT_GIS_INTERSECTION_SETTINGS_MODAL_HEADER', [this.selectedStep.name]):
                this.$t('PROCESS_EDITOR.STEPS.ADD_GIS_INTERSECTION_SETTINGS_MODAL_HEADER', [this.selectedStep.name]);
        },
    },
    methods: {
        ...mapActions({
            setMappings: 'processEditor/gisIntersectionSettings/doSetMappings',
            updateStep: "processEditor/stageForm/update",
            doFetch: 'processEditor/stageList/doFetch',
        }),
        show() {
            this.$refs['modal'].show();
        },
        hide() {
            this.$refs['modal'].hide();
        },
        saveIntersectionSettings() {
            let actionKey = this.selectedStep.key;
            if (actionKey.indexOf("_") !== -1) {
                actionKey = actionKey.slice(0, actionKey.indexOf("_"));
            }

            const payload = {
                key: actionKey,
                gisIntersectionsConfig: {
                    ...this.selectedStep.gisIntersectionsConfig,
                    gisIntersectionMetadataList: [
                        ...this.mappings.map((v, i) => {
                            return {
                                ...v,
                                order: i,
                            }
                        })
                    ],
                }
            }

            this.updateStep({ id: this.selectedStep.id, payload }).then(() => {
                this.doFetch({payload: {}, id: this.$route.params.id}).then(() => {
                    this.$emit("cancelForm");
                    this.hide();
                    return
                });
            });
        },
    },
    async created() {
        const metadataList = this.selectedStep?.gisIntersectionsConfig?.gisIntersectionMetadataList;

        this.setMappings(metadataList?.length ? metadataList.map(v => v) : []);
    },
};
</script>
